import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import ProductSchema from '../components/Shared/Schema/Product'
import FaqSchema from '../components/Shared/Schema/Faq'
import Header from '../components/WomanWeightLossPage/Header'
import HowItWorks from '../components/WomanWeightLossPage/HowItWorks'
import Info from '../components/WomanWeightLossPage/Info'
import Steps from '../components/WomanWeightLossPage/Steps'
import Reviews from '../components/WomanWeightLossPage/Reviews'
import Faq from '../components/WomanWeightLossPage/Faq'
import FoodAndDrugAdministration from '../components/WomanWeightLossPage/FoodAndDrugAdministration'
import References from '../components/WomanWeightLossPage/References'
import Wim from '../components/WomanWeightLossPage/Wim'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <HowItWorks howItWorks={data.howItWorks} />,
      priority: data.howItWorks?.priority || 99,
    },
    {
      render: <Info info={data.info} />,
      priority: data.info?.priority || 99,
    },
    {
      render: <Steps steps={data.steps} />,
      priority: data.steps?.priority || 99,
    },
    {
      render: <Reviews reviews={data.reviews} />,
      priority: data.reviews?.priority || 99,
    },
    {
      render: <Faq faq={data.faq} />,
      priority: data.faq?.priority || 99,
    },
    {
      render: <FoodAndDrugAdministration foodAndDrugAdministration={data.foodAndDrugAdministration} />,
      priority: data.foodAndDrugAdministration?.priority || 99,
    },
    {
      render: <References references={data.references} />,
      priority: data.references?.priority || 99,
    },
    {
      render: <Wim wim={data.wim} />,
      priority: data.wim?.priority || 99,
    },
  ].sort((a, b) => (a.priority >= b.priority ? 1 : -1))

  return (
    <Layout data={data} signUpUrl={data.header?.buttonUrl} signUpText={data.header?.buttonText}>
      <SEO {...data.seo} />
      <ProductSchema {...data.productSchema} />
      {(data?.faq?.faqList?.items || data?.faq?.items) && (
        <FaqSchema questions={data?.faq?.faqList?.items || data?.faq?.items} />
      )}
      <Header header={data.header} />
      {components.map(component => component.render)}
    </Layout>
  )
}
